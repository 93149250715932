import { axios } from '@/plugins/Axios';

const bareAxios = require('axios').default;

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    register,
    mockRegister,
  },
};

async function register(context, payload) {
  const baseUrl = 'https://rest.codeboxinc.com';
  return await bareAxios.post(`${baseUrl}/authentication`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

async function mockRegister(context, { businessId, username, password }) {
  return await axios.post(`businesses/${businessId}/mock-codeboxes/registration`, {
    username,
    password,
  });
}
