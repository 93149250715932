<template>
  <header :class="headerClass">
    <div class="row justify-content-between align-items-center">
      <div class="col logo align-items-center d-flex">
        <router-link :to="{ name: 'app.lease' }">
          <img
            :src="logoImage"
            alt="logo"
          >
        </router-link>
        <el-tag
          v-if="env !== 'production'"
          class="dark ml-2 d-flex justify-content-center align-items-center"
        >
          {{ env.capitalize() }}
        </el-tag>
      </div>
      <ul
        v-if="user && user.business && $viewport.gt.sm"
        class="main-nav col"
      >
        <router-link
          :to="{ name: 'app.properties' }"
          tag="a"
        >
          Properties
        </router-link>
        <router-link
          :to="{ name: 'app.lease' }"
          tag="a"
        >
          Leasing
        </router-link>
        <router-link
          v-show="false"
          :to="{ name: 'app.prospects' }"
          tag="a"
        >
          Prospects
        </router-link>
        <router-link
          :to="{ name: 'app.tours', query: { tab: 'today' } }"
          tag="a"
        >
          Tours
        </router-link>
        <router-link
          :to="{ name: 'app.condition-reports', query: { tab: 'requested' } }"
          tag="a"
        >
          Condition Reports
        </router-link>
      </ul>
      <div class="d-flex col justify-content-end align-items-center p-0">
        <div
          v-if="user"
          class="mr-3"
        >
          <SdNotifications />
        </div>
        <SdMobileHeaderDropdown v-if="$viewport.lt.md" />
        <div
          v-else-if="user && user.business && $viewport.gt.sm"
          class="d-flex"
        >
          <SdImageWithFallback
            :src="user.avatar_file_url"
            class="header-avatar align-self-center mr-3"
          >
            <template
              v-for="slotName in ['loading-placeholder', 'loading-error']"
              :slot="slotName"
            >
              <div
                v-if="slotName"
                :key="`${slotName}-placeholder`"
                class="font-10 d-flex align-items-center justify-content-center h-100"
                :style="{
                  backgroundColor: `#${userinitials.color}`,
                  color: userinitials.textColor
                }"
              >
                {{ userinitials.initials }}
              </div>
            </template>
          </SdImageWithFallback>

          <ElDropdown
            class="pointer align-self-center"
            hide-on-click
            trigger="click"
          >
            <div>
              {{ user.first_name }}
              <i class="sdicon-caret-down" />
              <div
                v-if="user.business.name"
                class="text-gray-dark"
              >
                {{ user.business.name }}
              </div>
            </div>
            <ElDropdownMenu>
              <router-link :to="{ name: 'app.profile.settings' }">
                <ElDropdownItem>
                  Profile
                </ElDropdownItem>
              </router-link>
              <router-link
                v-if="user.role === Role.ACCOUNT_OWNER"
                :to="{ name: 'app.transactions' }"
              >
                <ElDropdownItem>
                  Transactions
                </ElDropdownItem>
              </router-link>
              <router-link
                v-if="user.role === Role.ACCOUNT_OWNER"
                :to="{ name: 'app.billing' }"
              >
                <ElDropdownItem>
                  Billing
                </ElDropdownItem>
              </router-link>
              <router-link
                v-if="user.business.device_integration_enabled_at"
                :to="{ name: 'app.devices' }"
              >
                <ElDropdownItem>
                  Devices
                </ElDropdownItem>
              </router-link>
              <router-link :to="{ name: 'app.profile.business-settings' }">
                <ElDropdownItem>
                  Settings
                </ElDropdownItem>
              </router-link>
              <ElDropdownItem
                class="text-danger"
                @click.native="logout"
              >
                Log out
              </ElDropdownItem>
            </ElDropdownMenu>
          </ElDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { computed } from '@vue/composition-api';
import Role from '@/constants/Role';
import { getAvatarInitials } from '@/utils/AvatarUtil';
import SdImageWithFallback from '@/components/common/SdImageWithFallback';
import LogoFull from '@/assets/logo-white.svg';
import LogoIcon from '@/assets/icon.svg';
import SdNotifications from '../SdNotifications.vue';
import { redirectToSignIn } from '../../../router';

import SdMobileHeaderDropdown from './SdMobileHeaderDropdown.vue';

export default {
  name: 'SdHeader',
  components: {
    SdNotifications,
    SdMobileHeaderDropdown,
    SdImageWithFallback,
  },
  props: {
    bottomBorder: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, context) {
    const env = process.env.VUE_APP_ENV;
    const user = context.root.$store.state.Auth.user;
    const headerClass = computed(computeClass);
    const logoImage = computed(() => (context.root.$route.meta.layout === 'AuthLayout' ? LogoFull : LogoIcon));
    const userinitials = getAvatarInitials(user.full_name);

    return {
      userinitials,
      logoImage,
      headerClass,
      user,
      logout,
      env,
      Role,
    };

    function computeClass() {
      return props.bottomBorder ? ['bottom-border'] : [];
    }

    async function logout() {
      await context.root.$store.dispatch('Auth/logout');
      redirectToSignIn(context);
    }
  },
};
</script>

<style lang="scss" scoped>
header {
  background: $white;
  padding: 1.25rem 2rem 0 2rem;

  &.bottom-border {
    border-bottom: 1px solid gray-color();
  }

  .logo {
    padding: 1.25rem 0;
  }

  .header-avatar {
    flex-shrink: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  .main-nav {
    list-style: none;
    font-size: $--font-size-medium;
    margin: 0;
    padding: 0.25rem 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 4.6rem;

    a {
      color: #434449;
      padding: 0 1.5rem;
      border-bottom: 5px solid transparent;
      margin: 0 1rem;
      display: block;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: theme-color(primary);
      }

      &.router-link-active {
        color: gray-color(darker);
        border-color: theme-color(primary);
      }
    }
  }
}

.el-dropdown-menu .router-link-active > li {
  font-weight: $font-weight-bolder;
}
</style>
